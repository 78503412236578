/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveMeta } from "@dataResolvers";
import { Covid as PageTemplate } from "@pageTemplates";
import moment from "moment-timezone";

export const query = graphql`
  query {
    craft {
      entries(section: "teaching", limit: 1) {
        ... on Craft_teaching_teaching_Entry {
          type: typeHandle
          title
          teachingExcerpt
          mediaUrl
          mediaEmbed
          url
          postDate
          showDate
          teachingType
          teachingImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entry(section: "covid19") {
        ... on Craft_covid19_covid19_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          uid
          # hero
          heroHeading
          heroSubheading
          # policy
          splitContentImageText {
            ... on Craft_splitContentImageText_splitContent_BlockType {
              splitText
              splitImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              splitContentLink {
                text
                url
              }
            }
          }
          # reopening
          supportBlockHeading
          fullWidthText
          stages {
            ... on Craft_stages_stage_BlockType {
              heading
              subheading
              descriptor
              activeStage
            }
          }
          # visit virtually
          visitVirtuallyHeading
          visitVirtuallyDescriptor
          linkField {
            text
            url
          }
          # teachings
          visitVirtuallyImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          scrimOpacity0
          visitVirtuallyHeading2
          visitVirtuallySubheading2
          heroButtonLink {
            text
            url
          }
          # events
          eventContent
          eventExcerpt
          linkList {
            ... on Craft_linkList_button_BlockType {
              uid
              type: typeHandle
              linkObj {
                text
                url
              }
            }
            ... on Craft_linkList_link_BlockType {
              uid
              type: typeHandle
              linkObj {
                text
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
query {
  craft {
    entries(section: "teaching", limit: 1) {
      ... on Craft_teaching_teaching_Entry {
        type: typeHandle
        title
        teachingExcerpt
        mediaUrl
        mediaEmbed
        url
        postDate
showDate
        teachingType
        teachingImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
    entry(section: "covid19") {
      ... on Craft_covid19_covid19_Entry {
        uid
        # hero
        heroHeading
        heroSubheading
        # policy
        splitContentImageText {
          ... on Craft_splitContentImageText_splitContent_BlockType {
            splitText
            splitImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            splitContentLink {
              text
              url
            }
          }
        }
        # reopening
        supportBlockHeading
        fullWidthText
        stages {
          ... on Craft_stages_stage_BlockType {
            heading
            subheading
            descriptor
            activeStage
          }
        }
        # visit virtually
        visitVirtuallyHeading
        visitVirtuallyDescriptor
        linkField {
          text
          url
        }
        # teachings
        visitVirtuallyImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity0
        visitVirtuallyHeading2
        visitVirtuallySubheading2
        heroButtonLink {
          text
          url
        }
        # events
        eventContent
        eventExcerpt
        linkList {
          ... on Craft_linkList_button_BlockType {
            uid
            type: typeHandle
            linkObj {
              text
              url
            }
          }
          ... on Craft_linkList_link_BlockType {
            uid
            type: typeHandle
            linkObj {
              text
              url
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    // other
    heroHeading,
    heroSubheading,
    splitContentImageText,
    supportBlockHeading,
    fullWidthText,
    stages,
    visitVirtuallyHeading,
    visitVirtuallyDescriptor,
    linkField,
    visitVirtuallyImage,
    visitVirtuallyHeading2,
    visitVirtuallySubheading2,
    heroButtonLink,
    linkList,
    eventContent,
    eventExcerpt,
    scrimOpacity0,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      content: heroSubheading,
    },
    policy: splitContentImageText.map(split => {
      return {
        content: split.splitText,
        link: split.splitContentLink,
        image: resolveImage(split.splitImage),
      };
    }),
    reopening: {
      heading: supportBlockHeading,
      content: fullWidthText,
      steps: stages.map((stage, i) => {
        return {
          step: i + 1,
          metric: stage.subheading,
          description: stage.descriptor,
          active: stage.activeStage,
        };
      }),
      preview: craft.entries.map(t => {
        let label = "Read";
        switch (t.teachingType) {
          case "videos":
            label = "Watch";
            break;
          case "classes":
            label = "View";
            break;
          case "audioTeachings":
            label = "Hear";
            break;
          case "podcasts":
            label = "Listen";
            break;
          default:
            break;
        }
        return {
          name: t.title,
          description: t.teachingExcerpt,
          type: {
            text: t.teachingType,
            icon: t.teachingType,
          },
          time: moment(t.postDate)
            .tz("America/Los_Angeles")
            .format("MMM Do, YYYY"),
          mediaUrl: t.mediaUrl,
          mediaEmbed: t.mediaEmbed,
          image: resolveImage(t.teachingImage),
          button: { url: t.url, text: label },
        };
      })[0],
      links: linkList.map(link => {
        return {
          ...link,
          link: link.linkObj,
        };
      }),
      visit: {
        heading: visitVirtuallyHeading,
        content: visitVirtuallyDescriptor,
        button: linkField,
        side: {
          image: resolveImage(visitVirtuallyImage),
          scrimOpacity: scrimOpacity0,
          heading: visitVirtuallyHeading2,
          text: visitVirtuallySubheading2,
          button: heroButtonLink,
          donate: {
            title: eventContent,
            content: eventExcerpt,
          },
        },
      },
    },
    // ,
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
